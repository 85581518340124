import React, { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Plot from "react-plotly.js";
import spacetime from "spacetime";
import useFetchNodes from "../../hooks/useFetchNodes";
import useTimer from "../../hooks/useTimer";
import { makeStyles } from "@material-ui/core/styles";
import useFetchData from "../../hooks/useFetchData";
import { generateRandomFile, fillNodesMetricData } from "../../helpers";
import Navigation from "../Navigation/Navigation";

const useStyles = makeStyles((theme) => ({
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vw",
    paddingTop: "25%",
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "white",
  },
}));

const layout = {
  dragmode: "zoom",
  // mapbox: {
  //   style: "light",
  //   center: { lat: 51.11, lon: 17.03 },
  //   zoom: 11,
  // },
  geo: {
    projection: {
      type: 'robinson'
    }
  },
  margin: { r: 0, t: 0, b: 0, l: 0 },
  autoexpand: true,
  autosize: true,
  automargin: true,
  showlegend: false,
  showscale: false,
};

const Network = ({
  showNodes = true,
  showPaths = true,
  nodeMetric = 'none',
  maxNumOfPaths = 50,
  useTrend = false,
  onUsageChange = () => {}
}) => {
  const classes = useStyles();
  const [frames, setFrames] = useState([]);
  const [frameId, setFrameId] = useState(0);
  const [day, setDay] = useState(1);
  const [tags, setTags] = useState([])
  const [month, setMonth] = useState({ num: 9, days: 25 });
  const [tag, setTag] = useState(nodeMetric);

  const [{ nodes }, isLoading, isError, doFetchNodes] = useFetchNodes();
  const [
    { data: monthMetricsData, isLoading: isMonthMetricsLoading },
    doFetchMonthMetrics,
  ] = useFetchData(`out_${month.num}.json`, {});
  const [
    { data: monthUsageData, isLoading: isMonthUsageLoading },
    doFetchUsageMetrics,
  ] = useFetchData(`${month.num}-usage.json`, {});
  const { time, start, pause, reset, isRunning } = useTimer({
    endTime: month.days,
    initialTime: day,
  });

  useEffect(() => {
    if (monthMetricsData != null && monthMetricsData["1"] != null) {
      setFrames(
        Array(month.days+1)
          .fill(0)
          .map((val, id) => [
            ...nodes.list.map(
              fillNodesMetricData(
                monthMetricsData[id] || [],
                tag,
                useTrend,
                monthMetricsData[id - 1] || [],
                monthMetricsData[id + 1] || []
              )
            ),
          ])
      );
      setTags(Object.keys(monthMetricsData["1"]['Austria']))
    } else {
      setFrames(
        Array(month.days)
          .fill(0)
          .map((val, id) => [...nodes.list])
      );
      setTags([])
    }
  }, [
    isMonthMetricsLoading,
    day,
    showNodes,
    showPaths,
    tag,
    maxNumOfPaths,
    useTrend,
  ]);

  const data = frames[time] || [];
  const handleAnimationChange = (action) => {
    switch (action) {
      case "play":
        start();
        break;
      case "pause":
        pause();
        break;
      default:
        console.log(action);
        break;
    }
  };
  const onMonthChange = (month) => {
    setFrameId(0);
    reset(0);
    setMonth({ num: month.num, days: month.days });
    if (month.days < day) {
      setDay(1);
    }
    doFetchMonthMetrics(`out_${month.num}.json`);
    doFetchUsageMetrics(`${month.num}-usage.json`);
  };
  const onDayChange = (day) => {
    setDay(day);
    reset(day);
  };
  const onTagChange = (tag) => {
    setTag(tag)
  }
  return (
    <React.Fragment>
      <Navigation
        onDayChange={onDayChange}
        onMonthChange={onMonthChange}
        onAnimationChange={handleAnimationChange}
        onTagChange={onTagChange}
        tags={tags}
        day={time}
      />
      <Plot
        data={data}
        layout={layout}
        useResizeHandler={true}
        style={{ width: "100%", minHeight: "calc(100vh - 220px)" }}
      />
      {isMonthMetricsLoading && (
        <div className={classes.overlay}>
          <CircularProgress color="secondary" size={80} thickness={4} />
          <h1>Network data is loading... Please wait :)</h1>
        </div>
      )}
    </React.Fragment>
  );
};

export default Network;
