import React from "react";

import station from "./station-sample.png";
import path from "./path-sample.png";
import trendUp from './trend-up.png'
import trendDown from './trend-down.png'
import scale from './scale.png'

export const steps = [
  {
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        Hi, welcome to the quick guide on how to use this website. Please follow my lead to find out where are the most important parts of the website.
        `}
        <br />
      </div>
    ),
  },
  {
    selector: "#navigation",
    menuOpen: false,
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        Before we start to use the network let me show you the navigation.
        `}
      </div>
    ),
  },
  {
    selector: "#month-select",
    menuOpen: false,
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        First, we have the month selector. It allows you to chose which month the data should come from. The default one is September and each change requires downloading that month's data from the server.
        `}
      </div>
    ),
  },
  {
    selector: "#day-slider",
    menuOpen: false,
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        After selecting the month it's time to select a day of this month. Default day selected is always the 1st of the month but you can choose whatever you wish.
        `}
      </div>
    ),
  },
  {
    selector: "#date-info",
    menuOpen: false,
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        Here you can check what is exactly the time period you're looking at.
        `}
      </div>
    ),
  },
  {
    selector: "#tag-select",
    menuOpen: false,
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        By default, maps shows you the mostly used tag for each country. You can change that by selecting only tag which interests you the most.
        `}
      </div>
    ),
  },
  {
    selector: "#play-button",
    menuOpen: false,
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        Instead of just selecting the period, you can run animation by clicking "PLAY" button. It will change periods using a 2s interval until the "PAUSE" button is clicked.
        `}
      </div>
    ),
  },
  {
    selector: "#pause-button",
    menuOpen: false,
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        If you already started an animation then you can stop it any time you want. Just click this button to do it.
        `}
      </div>
    ),
  },
  {
    selector: ".plot-container",
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        This is the main working place, a place where the magic happens.`}
        <br />
        <br />
        {`
        Each country is represented by the currently selected "tag". `}<b>Bold text</b>{` shows which tag is the most dominant one. You can change that to compare not only mostly used tags but also those less important.
        `}
        <br />
        <img src={station} />
        <br />
        {`
        Please notice that colors are defined using log(1.1) scale.
        `}
        <br />
      </div>
    ),
    style: {
      maxWidth: 450
    }
  },
  {
    selector: "#menu-toggle",
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        Now let's check what are the different option you can use. Please click on the ">" arrow and then proceed to the next step.
        `}
      </div>
    ),
  },
  {
    selector: "#left-menu",
    menuOpen: true,
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        Here we have all settings for the network.
        `}
      </div>
    ),
  },
  {
    selector: "#useTrends",
    menuOpen: true,
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        If you don't want to just look on straight values of nodes you can switch to "Trend Mode". Trends allow you to see outliers within the data. Trends are shown as different colors base on which trend it is. You can have 3 different trends in the data:
        `}
        <br />
        <br />
        <b>Up trend:</b>
        {`
          When the current node is above the trend line. How "red" the node is indicates how high above the trend line you are .
        `}
        <img src={trendUp} style={{maxHeight: 200}}/>
        <br />
        <br />
        <b>Down trend:</b>
        {`
          When current nodes is below the trend line. How "blue" the node is how low below the trend line you are.
        `}
        <img src={trendDown} style={{maxHeight: 200}} />
        <br />
        <br />
        <b>Side trend:</b>
        {`
          When the current node is near the line.
        `}
        <br />
        <br />
        {`
          Trends are calculated as a simple moving average with history from the last four days. 
        `}
      </div>
    ),
    style: {
      maxWidth: 500,
      transform: 'translate(275px,10px)'
    }
  },
  {
    selector: "#guide-icon",
    content: ({ goTo, inDOM }) => (
      <div key={"tes"}>
        {`
        That's it :) You can always access the guide by clicking on this icon. Enjoy using the network.
        `}
      </div>
    ),
  },
];
