export const preprocessPaths = (paths = {}) => {
  return paths.map((row) => ({
    type: "scattermapbox",
    lat: row.lng,
    lon: row.lat,
    mode: "lines",
    line: { width: 4, color: "red" },
    id: row.id,
    text: `Connection between ${row.id}`,
  }));
};

export const nodeMetrics = [
  {
    name: "Top tag",
    value: "none",
  },
  {
    name: "In Degree",
    value: "ik",
  },
  {
    name: "Out Degree",
    value: "ok",
  },
  {
    name: "PageRank",
    value: "p",
  },
];

export const preprocessNodes = (nodes = {}) => {
  const codes = nodes.map((row) => row["CODE"]);
  const ids = nodes.map((row) => row["COUNTRY"]);
  const names = nodes.map((row) => row["COUNTRY"]);
  return [
    {
      codes: codes,
      ids: ids,
      text: names,
    },
  ];
};

export const generateRandomFile = () => {
  return Array(31)
    .fill(1)
    .reduce((acc, value, index) => {
      acc[index + 1] = Array(1440 / 15)
        .fill(0)
        .reduce((dayAcc, dayVal, dayId) => {
          dayAcc[dayId * 15] = Array(Math.floor(Math.random() * 15) + 1)
            .fill(1)
            .map((el, index) => {
              const org = Math.floor(Math.random() * 202);
              const dest = Math.floor(Math.random() * 204);
              return {
                o: org,
                d: org === dest ? dest + 1 : dest,
                c: Math.floor(Math.random() * 30) + 1,
              };
            });
          dayAcc[dayId * 15].sort((a, b) => b.c - a.c);
          return dayAcc;
        }, {});
      return acc;
    }, {});
};

export const fillPathsWithData = (paths = {}, nodes = {}) => {
  return (pathData = {}) => {
    const path = paths[`${pathData["o"]}-${pathData["d"]}`];
    return {
      type: "scattermapbox",
      lat: path == null ? [] : path.lng,
      lon: path == null ? [] : path.lat,
      mode: "lines",
      line: { width: pathData["c"] * 2, color: "rgba(255,0,0,0.5)" },
      id: path == null ? [] : path.id,
      hoverinfo: "text",
      hovertext:
        path == null
          ? "unknown"
          : `<span style="font-size: 18px">"${
              nodes[pathData["o"]].name
            }" <b>&#8658;</b> "${
              nodes[pathData["d"]].name
            }"</span> <br>Bicycles on path: ${pathData["c"]}`,
    };
  };
};

const scl = [
  [0, "rgb(0, 0, 200)"],
  [0.25, "rgb(0, 25, 255)"],
  [0.375, "rgb(0, 152, 255)"],
  [0.5, "rgb(44, 255, 150)"],
  [0.625, "rgb(151, 255, 0)"],
  [0.75, "rgb(255, 234, 0)"],
  [0.875, "rgb(255, 111, 0)"],
  [1, "rgb(255, 0, 0)"],
];

export const fillNodesMetricData = (
  metrics = {},
  metricKey = "none",
  usePrev = false,
  prevMetrics,
  prevMetricTwo
) => {
  return (node = {}) => {
    return {
      type: "choropleth",
      ids: node.ids,
      locations: node.ids,
      z: node.ids.map((id) => {
        if (metrics[id] == null) {
          return 0;
        }
        if (usePrev) {
          const currKey = metricKey !== "none" ? metricKey : Object.keys(metrics[id]).filter(key => metrics[id][key] === Math.max(...Object.values(metrics[id])))[0]
          const currMetric = metrics[id][currKey];
          const prev = prevMetrics[id] != null ? prevMetrics[id][currKey] : currMetric;
          const prev2 = prevMetricTwo[id] != null ? prevMetricTwo[id][currKey] : currMetric;
          const avg = [currMetric, prev, prev2].reduce((acc, val, id, arr) => {
            acc += val / arr.length;
            return acc;
          }, 0);

          return (currMetric - avg);
        }
        if (metricKey !== "none") {
          return Math.log(metrics[id][metricKey]) / Math.log(1.01);
        }
        return Math.log(Math.max(...Object.values(metrics[id]))) / Math.log(1.01);
      }),
      locationmode: "country names",
      hoverinfo: "text",
      showscale: false,
      hoverlabel: {
        bgcolor: "rgb(255, 234, 0)",
      },
      hovertext: node.ids.map((id) => {
        const currMetric = metrics[id];
        if (currMetric == null) {
          return `<span style="font-size: 14px"><b>Name:</b> ${id}</span> <br><b>Metrics:</b> UNAVAILABLE`;
        }
        const metricValues =
          metricKey !== "none"
            ? Object.entries(currMetric).filter(
                ([currKey, val]) => currKey === metricKey
              )
            : Object.entries(currMetric).sort(function (a, b) {
                return b[1] - a[1];
              });
        return `<span style="font-size: 14px"><b>Name:</b> ${id}</span> <br><b>Metrics:</b><br>${metricValues
          .map(
            ([tag, value], index) =>
              `${index === 0 ? `<b>` : ""}<span>${tag}</span> -> <span>${
                Number.isInteger(value) ? value : value.toString()
              }</span>${index === 0 ? `</b>` : ""}</br>`
          )
          .join("")}`;
      }),
    };
  };
};
