import React from "react";
import useFetchData from "./useFetchData";

import {preprocessNodes} from "../helpers";

const useFetchNodes = () => {
  const [
    { data: nodesData, isLoading: isNodesLoading, isError: isNodesError },
    doFetchNodes,
  ] = useFetchData("world_codes.json", []);
  return [
    {
      nodes: {
        list: preprocessNodes(nodesData),
        obj: nodesData
      },
    },
    isNodesLoading,
    isNodesError
  ];
};

export default useFetchNodes;
