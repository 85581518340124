import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  navigationContainer: {
    padding: theme.spacing(0, 8),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  sliders: {
    width: "90%",
  },
  buttons: {
    display: "flex",
    flexDirection: "column",
    marginRight: theme.spacing(4),
  },
  formElement: {
    marginBottom: theme.spacing(2),
  },
}));

const months = [
  {
    value: [8, 31],
    label: "August",
  },
  {
    value: [9, 25],
    label: "September",
  },
];

export default function Navigation({
  onDayChange,
  onHourChange,
  onMonthChange,
  onTagChange,
  onAnimationChange,
  time = 0,
  day = 1,
  tags=[],
}) {
  const classes = useStyles();
  const [month, setMonth] = useState(9);
  const [tag, setTag] = useState('none');
  const [days, setDays] = useState(25);
  const daysMarks = Array(days)
    .fill(0)
    .map((val, id) => ({ value: id + 1, label: id + 1 }));

  const handleMonthChange = (event) => {
    const val = event.target.value;
    const selectedMonth = months.find((curr) => curr.value[0] === val);
    setMonth(val);
    setDays(selectedMonth.value[1]);
    onMonthChange({
      num: val,
      days: selectedMonth.value[1],
    });
  };

  const handleDayChange = (event, newValue) => {
    onDayChange(newValue);
  };

  const handleTagChange = (event) => {
    const val = event.target.value;
    setTag(val);
    onTagChange(val);
  };

  const startAnimation = () => {
    onAnimationChange("play");
  };

  const stopAnimation = () => {
    onAnimationChange("pause");
  };

  return (
    <div className={classes.navigationContainer} id="navigation">
      <div className={classes.buttons}>
        <FormControl className={classes.formElement}
                     id="month-select">
          <InputLabel id="month-label">Month</InputLabel>
          <Select
            labelId="month-label"
            value={month}
            onChange={handleMonthChange}
          >
            {months.map((curr) => (
              <MenuItem key={curr.label} value={curr.value[0]}>
                {curr.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formElement}
                     id="tag-select">
          <InputLabel id="tag-label">Filter by tag:</InputLabel>
          <Select
            labelId="tag-label"
            value={tag}
            onChange={handleTagChange}
          >
            <MenuItem key={'best'} value={'none'}>
              Best
            </MenuItem>
            {tags.map((curr) => (
              <MenuItem key={curr} value={curr}>
                {curr}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <ButtonGroup disableElevation variant="contained" color="primary">
          <Button onClick={startAnimation} id="play-button">
            Play
          </Button>
          <Button onClick={stopAnimation} id="pause-button">
            Pause
          </Button>
        </ButtonGroup>
      </div>
      <div className={classes.sliders}>
        <Typography variant="h5" color="inherit" noWrap id="date-info">
          Date: {day} {months[month - 8].label} 2020
        </Typography>
        <Slider
          id="day-slider"
          value={day}
          onChange={handleDayChange}
          defaultValue={1}
          min={1}
          max={days}
          aria-labelledby="day-slider"
          marks={daysMarks.splice(0, days)}
          step={1}
        />
      </div>
    </div>
  );
}
